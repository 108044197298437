<template>
  <div>
    <v-card-text class="px-0 py-0">
      <div v-if="currentStep == 1">
        <v-form>
          <v-col cols="12" class="px-0 py-0 pb-4">
            <p class="black--text">
              By linking partner accounts in Prelude Connect you authorize your
              designated partner to receive any and all protected health
              information maintained by your clinic (whether in Prelude Connect
              or otherwise), including but not limited to History/Physical
              Exams, Past/Present Medications, Lab Results, Physician’s Orders,
              Patient Allergies, Operation Reports, Consultation Reports,
              Progress Notes, Discharge Summary(ies), Diagnostic Test Reports,
              EKG/Cardiology Reports, Pathology Reports, Billing Information,
              Radiology Reports & Images. Further, I specifically consent to the
              release of the following information:
            </p>
          </v-col>

          <v-checkbox
            class="grey--text align"
            label="Mental Health Records (including psychotherapy
              notes)."
            v-model="data.check_1"
          />
          <v-checkbox
            class="grey--text align"
            label="Genetic Information (including Genetic Test
              Results)."
            v-model="data.check_2"
          />
          <v-checkbox
            class="grey--text align"
            label="Drug, Alcohol, or Substance Abuse Records."
            v-model="data.check_3"
          />
          <v-checkbox
            class="grey--text align"
            label="HIV/AIDS Test Results/Treatment."
            v-model="data.check_4"
          />

          <v-btn
            class="mt-8"
            color="primary"
            :disabled="
              !data.check_1 || !data.check_2 || !data.check_3 || !data.check_4
            "
            rounded
            block
            large
            @click="handlerNextStep"
          >
            Agree & Enter Code
          </v-btn>
        </v-form>
      </div>

      <div v-if="currentStep == 2">
        <v-col
          md="12"
          class="flex align-center justify-center text-center"
          v-if="loading"
        >
          <v-progress-circular v-if="loading" color="primary" indeterminate />
        </v-col>
        <v-col
          md="12"
          class="flex align-center justify-center text-center"
          v-else
        >
          <p class="text-header" style="color: #1f6070">
            Enter a Prelude Connect partner code to Link you Connect account
            with your partner.
          </p>
        </v-col>

        <v-col
          md="12"
          class="flex align-center justify-center text-center"
          v-if="!loading"
        >
          <v-text-field
            label="Code"
            maxLength="6"
            v-model="code"
            class="pt-0"
            @keyup="uppercase"
            height="70px"
            style="font-size: 1.8rem; color: #1f6070"
            placeholder="Enter the code"
            :disabled="loading"
          />
        </v-col>

        <v-btn
          v-if="!loading"
          class="mt-8"
          color="primary"
          rounded
          block
          large
          :loading="loading"
          :disabled="!code || code.length != 6"
          @click="codeInvitePartner"
        >
          Confirm Partner Code
        </v-btn>

        <v-btn
          v-if="!loading"
          class="mt-8"
          color="red"
          rounded
          block
          large
          to="/my-partner/"
        >
          Cancel
        </v-btn>
      </div>
    </v-card-text>
    <v-spacer />
  </div>
</template>

<script>
import { api } from '../../sharedPlugin';
export default {
  data() {
    return {
      currentStep: 1,
      lastStep: 2,
      valid: true,
      loading: false,
      code: null,
      data: {
        check_1: false,
        check_2: false,
        check_3: false,
        check_4: false
      }
    };
  },
  computed: {},
  methods: {
    handlerNextStep() {
      this.currentStep++;
    },
    uppercase() {
      this.code = this.code.toUpperCase();
    },
    codeInvitePartner() {
      this.loading = true;
      api.PartnerConnect.codeInvitePartner(this.code)
        .then((res) => {
          this.loading = false;
          this.code = null;
          if (res.error) {
            this.$store.commit('showSnackbar', {
              message: res.error,
              color: 'red'
            });
          } else if (res && res.length > 0) {
            this.$store.commit('setPulling', true);
            this.$router.push('/my-partner');
          }
        })
        .catch((err) => {
          console.log('ERROR:', err);
          this.$store.commit('showSnackbar', {
            message: 'Invalid code',
            color: 'red'
          });
          this.loading = false;
        });
    },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      } else {
        this.isCodeExpired = true;
      }
    }
  },
  mounted() {}
};
</script>

<style scoped>
.theme--primary.v-card {
  color: '#1F6070';
}
.text-header {
  font-size: 22px;
  color: '#1F6070';
}

.text-code {
  font-size: 38px;
  color: '#1F6070';
  font-weight: bold;
}
</style>
